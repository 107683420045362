import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import Link from 'next/link';

export default function Index({}) {
  const screens = useBreakpoint();
  return (
    <div className="container">
      <header>
        <div className="logos">
          <Image src="/logo.png" alt="CFAO" width={200} height={67} />
          <span style={{
            margin: '0 100px',
          }} />
          <Image src="/punch-line.png" alt="CFAO" width={150} height={90} />
        </div>
        <div className="heading">
          <h1>LEADERSHIP DAY</h1>
          <h2>21st of July 2023</h2>
          <h3>02:00PM</h3>
        </div>
      </header>
      <ul>
        <li>
          <Link href="/live/cljy1hg980016jz01b3lnamu6" locale="fr">
            <a>
              <Image
                src={'/franceenglish.png'}
                alt="FranÃ§ais"
                width={screens.md ? 56 : 80}
                height={screens.md ? 56 : 80}
                
              />
              <p>Version originale</p>
            </a>
          </Link>
        </li>
        <li>
          <Link href="/live/cljy1kiii001gj201ucl9bxsf" locale="en">
            <a>
              <Image
                src={'/united-kingdom.png'}
                alt="English"
                width={screens.md ? 56 : 64}
                height={screens.md ? 56 : 64}
              />
              <p>English version</p>
            </a>
          </Link>
        </li>
      </ul>
      <div className="language"></div>
      {/* <div className="image__container">
        <Image src={"/faces.png"} alt="English" width={1449} height={435} />
      </div> */}
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: ${screens.md ? '64px' : '32px'};
        }
        a {
          text-decoration: none;
        }
        header {
            margin: 0 0 40px;
        }
        header .logos {
                       display: flex;
            align-items: center;
            margin: 0 0 60px;
        }
        header .logos img {
            margin: 0 16px;
        }
        header h1 {
          font-size: ${screens.md ? '32px' : '20px'};
          letter-spacing: 4px;
          margin: 0 0 8px;
        }
        header h2 {
          font-size: ${screens.md ? '22px' : '16px'};
          letter-spacing: 6px;
          font-weight: 400;
        }
        header h3 {
          font-size: ${screens.md ? '14px' : '10px'};
          letter-spacing: 3px;
          font-weight: 400;
        }
        h1,
        h2,
        h3,
        p {
            font-family: 'Open Sans', sans-serif;
            color: #3d3c5c;
          margin: 0 0 4px;
        }
        p {
          padding: 0 ${screens.md ? '32px' : '0'};
        }
        header {
          text-align: center;
          margin: 0 0 16px;
        }
        ul {
          display: flex;
          flex-direction: ${screens.md ? 'row' : 'column'};
          align-items: center;
          padding: 0;
          margin: 0;
          list-style: none;
          margin: 0 0 ${screens.md ? '32px' : '16px'};
        }
        li {
          width: ${screens.md ? '50%' : '100%'};
          max-width: ${screens.md ? '100px' : '100%'};
          text-align: center;
          border: 1px solid #eee;
          border-radius: 4px;
          margin: ${screens.md ? '24px' : '16px 0'};
          position: relative;
          top: 0;
          padding: ${screens.md ? '16px' : '16px'};
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;      
          background: #efefef;
          transition: all 0.2s ease-in-out;
        }
        li:hover {
          top: -2px;
          background: rgba(0,0,0,0.1)
          box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
          cursor: pointer;
          background: #ebebeb;
        }
        li h3 {
          font-size: ${screens.md ? '16px' : '12px'};
          max-width:  ${screens.md ? '220px' : '160px'};
          min-height: 44px;
          margin: 0 auto 16px;
        }
        li p {
          margin: 16px 0 0;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          padding: 0;
        }
        .image__container {
          max-width: 480px;
          margin-top: ${screens.md ? '64px' : '32px'};
        }
      `}</style>
    </div>
  );
}
